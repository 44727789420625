<template>
    <div>
        <div ref="mychildcomponent" hidden id='customerLedgerPrintReport' class="col-md-7">
            <div v-if="itemTotal<=22">
                <div class="col-md-12" style="height:45mm;border:2px solid #000000;background-color:white" v-if="IsPaksitanClient">
                    <table class="table table-borderless">
                        <tr>
                            <td style="width:30%;" class="text-left pt-0 pb-0 pl-0 pr-0">
                                <img :src="headerFooters.company.logoPath" style="width:auto;max-width:300px; max-height:100px;  margin:0 !important;padding:5px">
                            </td>
                            <td style="width:40%;" class="text-center ">

                                <u style="font-size:14px;color:black !important;font-weight:bold;">
                                    Sales Tax Invoice
                                </u><br />
                                <span style="font-size:23px;color:black !important;font-weight:bold;">{{headerFooters.company.nameEnglish}}</span><br />
                                <span style="font-size:14px;color:black !important;font-weight:bold;">{{headerFooters.company.addressEnglish}}</span><br />
                                <span style="font-size:14px;color:black !important;font-weight:bold;float:left">NTN :&nbsp;&nbsp;&nbsp; {{headerFooters.company.vatRegistrationNo}}</span>&nbsp;
                                <span style="font-size:14px;color:black !important;font-weight:bold;float:right">STR:&nbsp;&nbsp;&nbsp;   {{headerFooters.company.companyRegNo}}</span><br />
                                <span style="font-size:23px;color:black !important;font-weight:bold;padding-bottom:5px !important">
                                    <span style="font-size:20px;color:black !important;font-weight:bold;padding-bottom:5px !important" v-if="formName=='Customer'">{{ $t('CustomerLedgerReport.CustomerLedgerReport') }}</span>
                                    <span style="font-size:20px;color:black !important;font-weight:bold;padding-bottom:5px !important" v-if="formName=='Supplier'">{{ $t('CustomerLedgerReport.SupplierLedgerReport') }}</span>
                                </span>

                            </td>
                            <td style="width:30%;" class="text-left "> </td>

                        </tr>


                    </table>



                </div>
                <div class="col-md-12" v-else-if="isHeaderFooter=='true'">
                    <table class="table table-borderless">
                        <tr>
                            <td style="width:36%;" class="text-left pt-0 pb-0 pl-0 pr-0">
                                <p class="mb-0">
                                    <span style="font-size:23px;color:black !important;font-weight:bold;">{{headerFooters.company.nameEnglish}}</span><br />
                                    <span style="font-size:15px;color:black !important;font-weight:bold;">{{headerFooters.company.categoryEnglish}}</span><br />
                                    <span style="font-size:14px;color:black !important;font-weight:bold;">VAT No.: {{headerFooters.company.vatRegistrationNo}}</span><br />
                                    <span style="font-size:14px;color:black !important;font-weight:bold;">Cr No.:{{headerFooters.company.companyRegNo}}</span><br />
                                    <span style="font-size:13px;color:black !important;font-weight:bold;">
                                        Tel: {{headerFooters.company.phoneNo}}
                                    </span>
                                </p>
                            </td>
                            <td style="width:26%;" class="text-center pt-0 pb-0 pl-0 pr-0">
                                <img :src="headerFooters.company.logoPath" style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                            </td>
                            <td style="width:38%;" class="pt-0 pb-0 pl-0 pr-0">
                                <p class="text-right mb-0" v-if="arabic=='true'">
                                    <span style="font-size:23px;color:black !important;font-weight:bold;">{{headerFooters.company.nameArabic}}.</span><br />
                                    <span style="font-size:15px;color:black !important;font-weight:bold;">{{headerFooters.company.categoryArabic}}</span><br />
                                    <span style="font-size:14px;color:black !important;font-weight:bold;">رقم ضريبة القيمة المضافة: {{headerFooters.company.vatRegistrationNo}}</span><br />
                                    <span style="font-size:14px;color:black !important;font-weight:bold;">رقم السجل التجاري :{{headerFooters.company.companyRegNo}}</span><br />
                                    <span style="font-size:13px;color:black !important;font-weight:bold;">
                                        هاتف: {{headerFooters.company.phoneNo}}:
                                    </span>
                                </p>
                            </td>
                        </tr>

                        <tr>

                            <td style="width:100%;" class="pt-0 pb-0 pl-0 pr-0" colspan="3">
                                <div style="text-align: center;">
                                    <span style="font-size:20px;color:black !important;font-weight:bold;padding-bottom:5px !important" v-if="formName=='Customer'">{{ $t('CustomerLedgerReport.CustomerLedgerReport') }}</span>
                                    <span style="font-size:20px;color:black !important;font-weight:bold;padding-bottom:5px !important" v-if="formName=='Supplier'">{{ $t('CustomerLedgerReport.SupplierLedgerReport') }}</span>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>            <!--INFORMATION-->
                <div v-else style="height: 45mm;">
                </div>
                <!--INFORMATION-->
                <div style="height:15mm;margin-top:1mm; border:2px solid #000000;">
                    <div class="row">
                        <div class="col-md-12 ml-2 mr-2">
                            <table class="table table-borderless">
                                <!--Row 1-->
                                <tr>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:14%; font-weight:bolder;text-align:right;color:black !important;font-size:14px !important;">From Date:</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;">{{getDate(fromDate)}}</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:11%;font-weight:bolder;font-size:14px !important;color:black !important;" v-if="arabic=='true'">:من التاريخ</td>

                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:14%; font-weight:bolder;text-align:right;color:black !important;font-size:14px !important;">To Date:</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;">{{getDate(toDate)}}</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:11%;font-weight:bolder;font-size:14px !important;color:black !important;" v-if="arabic=='true'">:حتى الآن</td>
                                </tr>
                            </table>
                        </div>

                    </div>
                </div>
                <div  style="border:2px solid black">
                    <div class="col-md-12" style="height:270mm">
                        <table class="table">
                            <tr class="heading" style="font-size:15px !important;padding-top:5px;">
                                <th class="text-left" style="padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">#</th>
                                <th class="text-center" style="padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">  {{ $t('CustomerLedgerReport.Code') }}</th>
                                <th class="text-center" style="padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">
                                    <span v-if="formName=='Customer'">{{ $t('CustomerLedgerReport.CustomerLedgerReport') }}</span>
                                    <span v-else>{{ $t('CustomerLedgerReport.SupplierLedgerReport') }}</span>
                                </th>
                                <th class="text-center" style="padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">{{ $t('CustomerLedgerReport.Account')}} {{$t('CustomerLedgerReport.Code')}}</th>
                                <th class="text-center" style="padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"> {{ $t('Location.VatNo') }}</th>
                                <th class="text-right" style="padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"> {{ $t('CustomerLedgerReport.Amount') }}</th>


                            </tr>
                            <tr style="font-size:13px;font-weight:bold;" v-for="(contact, index) in list" v-bind:key="contact.id">
                                <td class="text-left" style="padding-top:8px !important; padding-bottom:3px !important;border-top:0 !important; border-bottom:0 !important;color:black !important;">{{index+1}}</td>
                                <td class="text-center" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">{{contact.contactCode}}</td>
                                <td class="text-center" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
                                    <span v-if="language=='en'">{{contact.contactName==''?contact.contactNameArabic:contact.contactName}}</span>
                                    <span v-else>{{contact.contactNameArabic==''?contact.contactName:contact.contactNameArabic}}</span>
                                </td>
                                <td class="text-center" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">{{contact.accountCode}}</td>
                                <td class="text-center" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">{{contact.vatNo}}</td>
                                <td class="text-right" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
                                    {{contact.amount>0?'Dr':'Cr'}} {{nonNegative(contact.amount) }}
                                </td>


                            </tr>

                            <tr style="font-size:15px;font-weight:bold;background-color:azure">
                                <td colspan="3" class="text-center" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 30px !important; padding-bottom: 3px !important;color:black !important;">{{ $t('CustomerLedgerReport.TotalDebit') }}:  {{(parseFloat(totalDebit)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                <td colspan="2" class="text-left" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 30px !important; padding-bottom: 3px !important;color:black !important;">
                                    {{ $t('CustomerLedgerReport.TotalCredit') }}:{{(parseFloat(totalCredit)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}

                                </td>

                                <td colspan="3" class="text-left" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 30px !important; padding-bottom: 3px !important;color:black !important;">{{ $t('CustomerLedgerReport.Total') }}: {{(parseFloat(totalDebit-totalCredit)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>

                            </tr>

                        </table>

                    </div>
                    <div class="col-12 pl-2 pr-2" style=" background-color:white;font-size:16px">
                        <div class="col-12">
                            <table class="table text-center">
                                <tr>
                                    <td style="width: 33%;text-decoration-line: overline;border:0px;color:black;font-weight:bold">
                                        <span style=" border-top: 1px solid black;">
                                            Prepared By
                                        </span>
                                    </td>
                                    <td style="width: 33%;text-decoration-line: overline;border:0px;color:black;font-weight:bold">
                                        <span style=" border-top: 1px solid black;">
                                            Approved By
                                        </span>

                                    </td>
                                    <td style="width: 33%;text-decoration-line: overline;border:0px;color:black;font-weight:bold">

                                        <span style=" border-top: 1px solid black;">
                                            Received By
                                        </span>


                                    </td>
                                </tr>


                            </table>
                            <table class="table text-center">

                                <tr>
                                    <td style="width: 20%;border:0px;color:black;font-weight:bold">
                                    </td>
                                    <td style="width: 60%;border:0px;color:black;font-weight:bold">
                                        Tel: &nbsp; &nbsp; &nbsp;{{headerFooters.company.phoneNo}} &nbsp; &nbsp; &nbsp; Email:&nbsp; &nbsp; &nbsp;{{headerFooters.company.email}}
                                    </td>
                                    <td style="width: 20%;border:0px;color:black;font-weight:bold">
                                    </td>
                                </tr>

                            </table>
                        </div>

                    </div>
                </div>
            </div>
            <div v-else>
                <div class="col-md-12" style="height:45mm;border:2px solid #000000;background-color:white" v-if="IsPaksitanClient">
                    <table class="table table-borderless">
                        <tr>
                            <td style="width:30%;" class="text-left pt-0 pb-0 pl-0 pr-0">
                                <img :src="headerFooters.company.logoPath" style="width:auto;max-width:300px; max-height:100px;  margin:0 !important;padding:5px">
                            </td>
                            <td style="width:40%;" class="text-center ">

                                <u style="font-size:14px;color:black !important;font-weight:bold;">
                                    Sales Tax Invoice
                                </u><br />
                                <span style="font-size:23px;color:black !important;font-weight:bold;">{{headerFooters.company.nameEnglish}}</span><br />
                                <span style="font-size:14px;color:black !important;font-weight:bold;">{{headerFooters.company.addressEnglish}}</span><br />
                                <span style="font-size:14px;color:black !important;font-weight:bold;float:left">NTN :&nbsp;&nbsp;&nbsp; {{headerFooters.company.vatRegistrationNo}}</span>&nbsp;
                                <span style="font-size:14px;color:black !important;font-weight:bold;float:right">STR:&nbsp;&nbsp;&nbsp;   {{headerFooters.company.companyRegNo}}</span><br />
                                <span style="font-size:23px;color:black !important;font-weight:bold;padding-bottom:5px !important">
                                    <span style="font-size:20px;color:black !important;font-weight:bold;padding-bottom:5px !important" v-if="formName=='Customer'">{{ $t('CustomerLedgerReport.CustomerLedgerReport') }}</span>
                                    <span style="font-size:20px;color:black !important;font-weight:bold;padding-bottom:5px !important" v-if="formName=='Supplier'">{{ $t('CustomerLedgerReport.SupplierLedgerReport') }}</span>
                                </span>

                            </td>
                            <td style="width:30%;" class="text-left "> </td>

                        </tr>


                    </table>



                </div>
                <div class="col-md-12" v-else-if="isHeaderFooter=='true'">
                    <table class="table table-borderless">
                        <tr>
                            <td style="width:36%;" class="text-left pt-0 pb-0 pl-0 pr-0">
                                <p class="mb-0">
                                    <span style="font-size:23px;color:black !important;font-weight:bold;">{{headerFooters.company.nameEnglish}}</span><br />
                                    <span style="font-size:15px;color:black !important;font-weight:bold;">{{headerFooters.company.categoryEnglish}}</span><br />
                                    <span style="font-size:14px;color:black !important;font-weight:bold;">VAT No.: {{headerFooters.company.vatRegistrationNo}}</span><br />
                                    <span style="font-size:14px;color:black !important;font-weight:bold;">Cr No.:{{headerFooters.company.companyRegNo}}</span><br />
                                    <span style="font-size:13px;color:black !important;font-weight:bold;">
                                        Tel: {{headerFooters.company.phoneNo}}
                                    </span>
                                </p>
                            </td>
                            <td style="width:26%;" class="text-center pt-0 pb-0 pl-0 pr-0">
                                <img :src="headerFooters.company.logoPath" style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                            </td>
                            <td style="width:38%;" class="pt-0 pb-0 pl-0 pr-0">
                                <p class="text-right mb-0" v-if="arabic=='true'">
                                    <span style="font-size:23px;color:black !important;font-weight:bold;">{{headerFooters.company.nameArabic}}.</span><br />
                                    <span style="font-size:15px;color:black !important;font-weight:bold;">{{headerFooters.company.categoryArabic}}</span><br />
                                    <span style="font-size:14px;color:black !important;font-weight:bold;">رقم ضريبة القيمة المضافة: {{headerFooters.company.vatRegistrationNo}}</span><br />
                                    <span style="font-size:14px;color:black !important;font-weight:bold;">رقم السجل التجاري :{{headerFooters.company.companyRegNo}}</span><br />
                                    <span style="font-size:13px;color:black !important;font-weight:bold;">
                                        هاتف: {{headerFooters.company.phoneNo}}:
                                    </span>
                                </p>
                            </td>
                        </tr>

                        <tr>

                            <td style="width:100%;" class="pt-0 pb-0 pl-0 pr-0" colspan="3">
                                <div style="text-align: center;">
                                    <span style="font-size:20px;color:black !important;font-weight:bold;padding-bottom:5px !important" v-if="formName=='Customer'">{{ $t('CustomerLedgerReport.CustomerLedgerReport') }}</span>
                                    <span style="font-size:20px;color:black !important;font-weight:bold;padding-bottom:5px !important" v-if="formName=='Supplier'">{{ $t('CustomerLedgerReport.SupplierLedgerReport') }}</span>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>            <!--INFORMATION-->
                <div v-else style="height: 45mm;">
                </div>
                <!--INFORMATION-->
                <div style="height:15mm;margin-top:1mm; border:2px solid #000000;">
                    <div class="row">
                        <div class="col-md-12 ml-2 mr-2">
                            <table class="table table-borderless">
                                <!--Row 1-->
                                <tr>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:14%; font-weight:bolder;text-align:right;color:black !important;font-size:14px !important;">From Date:</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;">{{getDate(fromDate)}}</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:11%;font-weight:bolder;font-size:14px !important;color:black !important;" v-if="arabic=='true'">:من التاريخ</td>

                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:14%; font-weight:bolder;text-align:right;color:black !important;font-size:14px !important;">To Date:</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;">{{getDate(toDate)}}</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:11%;font-weight:bolder;font-size:14px !important;color:black !important;" v-if="arabic=='true'">:حتى الآن</td>
                                </tr>
                            </table>
                        </div>

                    </div>
                </div>
                <div class="row mt-4 " style="border:2px solid black">
                    <table class="table">
                        <tr class="heading" style="font-size:15px !important;padding-top:5px;">
                            <th class="text-left" style="padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">#</th>
                            <th class="text-center" style="padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">  {{ $t('CustomerLedgerReport.Code') }}</th>
                            <th class="text-center" style="padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">
                                <span v-if="formName=='Customer'">{{ $t('CustomerLedgerReport.CustomerLedgerReport') }}</span>
                                <span v-else>{{ $t('CustomerLedgerReport.SupplierLedgerReport') }}</span>
                            </th>
                            <th class="text-center" style="padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">{{ $t('CustomerLedgerReport.Account')}} {{$t('CustomerLedgerReport.Code')}}</th>
                            <th class="text-center" style="padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"> {{ $t('Location.VatNo') }}</th>
                            <th class="text-right" style="padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"> {{ $t('CustomerLedgerReport.Amount') }}</th>


                        </tr>
                        <tr style="font-size:13px;font-weight:bold;" v-for="(contact, index) in list" v-bind:key="contact.id">
                            <td class="text-left" style="padding-top:8px !important; padding-bottom:3px !important;border-top:0 !important; border-bottom:0 !important;color:black !important;">{{index+1}}</td>
                            <td class="text-center" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">{{contact.contactCode}}</td>
                            <td class="text-center" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
                                <span v-if="language=='en'">{{contact.contactName==''?contact.contactNameArabic:contact.contactName}}</span>
                                <span v-else>{{contact.contactNameArabic==''?contact.contactName:contact.contactNameArabic}}</span>
                            </td>
                            <td class="text-center" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">{{contact.accountCode}}</td>
                            <td class="text-center" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">{{contact.vatNo}}</td>
                            <td class="text-right" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
                                {{contact.amount>0?'Dr':'Cr'}} {{nonNegative(contact.amount) }}
                            </td>


                        </tr>

                        <tr style="font-size:15px;font-weight:bold;background-color:azure">
                            <td colspan="3" class="text-center" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 30px !important; padding-bottom: 3px !important;color:black !important;">{{ $t('CustomerLedgerReport.TotalDebit') }}:  {{(parseFloat(totalDebit)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                            <td colspan="2" class="text-left" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 30px !important; padding-bottom: 3px !important;color:black !important;">
                                {{ $t('CustomerLedgerReport.TotalCredit') }}:{{(parseFloat(totalCredit)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}

                            </td>

                            <td colspan="3" class="text-left" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 30px !important; padding-bottom: 3px !important;color:black !important;">{{ $t('CustomerLedgerReport.Total') }}: {{(parseFloat(totalDebit-totalCredit)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>

                        </tr>

                    </table>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
    import moment from "moment";
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        props: ['formName', 'isPrint', 'fromDate', 'toDate', 'headerFooter', 'contactList'],
        data: function () {
            return {
                list: [],
                invoicePrint: '',
                Print: false,
                openingCash: 0,
                totalBalance: 0,
                render: 0,
                customerNameEn: '',
                customerNameAr: '',
                isHeaderFooter: '',
                english: '',
                arabic: '',

                address: '',
                phoneNumber: '',
                customerNo: '',
                customerVat: '',
                headerFooters: {
                    footerEn: '',
                    footerAr: '',
                    company: ''
                },

                itemTotal: 0,
                listItemP1: [],
                listItemP2: [],
                listItemP3: [],
                IsPaksitanClient: false,

            }
        },
        computed: {
            totalDebit: function () {

                return this.contactList.reduce(function (a, c) {

                    if (c.amount > 0) {
                        return a + Number((c.amount.toFixed(3).slice(0, -1)) || 0)

                    }
                    else {
                        return a + 0;
                    }


                }, 0)
            },

            totalCredit: function () {


                return this.contactList.reduce(function (a, c) {

                    if (c.amount <= 0) {
                        return a + Number((c.amount.toFixed(3).slice(0, -1) < 0 ? c.amount.toFixed(3).slice(0, -1) * -1 : c.amount.toFixed(3).slice(0, -1)) || 0)

                    }
                    else {
                        return a + 0;
                    }


                }, 0)
            },

        },

        mounted: function () {
            this.language = this.$i18n.locale;
            this.IsPaksitanClient = localStorage.getItem('IsPaksitanClient') == "true" ? true : false;

            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.invoicePrint = localStorage.getItem('InvoicePrint');
            var root = this;
            this.headerFooters = this.headerFooter
            root.Print = root.isPrint;
            this.list = this.contactList;
            if (this.list.length > 0) {
                var totalItem = this.list;

                this.itemTotal = this.list.length;
                if (totalItem < 22) {
                    for (var i = 0; i < totalItem; i++) {
                        root.listItemP1.push(root.list[i]);
                    }
                }
                else if (totalItem >= 22 && totalItem < 38) {
                    for (var k = 0; k < totalItem; k++) {
                        if (k < 22) {
                            root.listItemP1.push(root.list[k]);
                        }
                        else {
                            root.listItemP2.push(root.list[k]);
                        }
                    }
                }
                setTimeout(function () {
                    root.printInvoice();
                }, 125)
            }

        },
        methods: {
            getDate: function (date) {
                if (date == null || date == '')
                    return '';
                else {
                    return moment(date).format('LLL');

                }
            },
            printInvoice: function () {


                var form = new FormData();
                form.append('htmlString', this.$refs.mychildcomponent.innerHTML);
                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.post('/Report/PrintPdf', form, { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        var date = moment().format('DD MMM YYYY');
                        if (root.formName == 'Customer') {
                            link.setAttribute('download', 'Customer Ledger Report ' + date + '.pdf');

                        }
                        else {
                            link.setAttribute('download', 'Supplier Ledger Report ' + date + '.pdf');
                        }
                        document.body.appendChild(link);
                        link.click();

                        root.$emit('close');
                    });
            },

            nonNegative: function (value) {
                return parseFloat(Math.abs(value)).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
            },
            convertDate: function (x) {
                return moment(x).format('DD MMM YYYY');
            }

        }
    }
</script>