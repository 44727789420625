<template>
    <div class=" row">
        <div class="col-1"></div>
        <div class="col-10" style="color:black !important;background-color:white !important;width:100%;height:100%">

            <div>
                <!--HEADER-->
                <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8" v-bind:class="$i18n.locale == 'en' ? 'text-left' : 'arabicLanguage'">
                    </div>
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4" v-bind:class="$i18n.locale == 'en' ? 'text-right' : 'text-left'">
                        <button class="btn btn-outline-danger  mr-2"
                                v-on:click="goToSale">
                            <i class="fas fa-arrow-circle-left fa-lg"></i>
                        </button>

                    </div>
                </div>
                <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                    <div class="col-sm-12 " style="background-color:white !important">
                        <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                    </div>
                </div>
                <div style="height:40mm;background-color:white !important" v-else></div>

                <div class="row pt-2" style="background-color:white !important">
                    <div class="col-7 " style="background-color:white !important">
                        <p style="font-size:17px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">WE ARE IMPROVING YOUR BUSINESS</p>
                        <p style="font-size:12px;color:black !important;margin-bottom:0!important;background-color:white !important"><img src="/images/pin.png" style="width:auto;max-width:15px; max-height:15px;"> <span>{{headerFooters.company.addressArabic}}</span></p>
                        <p style="font-size:12px;color:black !important;margin-bottom:0!important;background-color:white !important"><img src="/images/Transparent.png" style="width:auto;max-width:15px; max-height:15px;">  {{headerFooters.company.addressEnglish}}</p>
                        <p style="font-size:12px;color:black !important;margin-bottom:0!important;background-color:white !important"><img src="/images/phone.png" style="width:auto;max-width:15px; max-height:15px;"> {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</p>
                        <div class="row mt-3" style="font-size: 14px;background-color:white !important">
                            <div class="col-9" style="margin-top:15px !important">
                                <div class="row">
                                    <div class="col-4" style="font-weight:normal;font-size:14px" v-if="formName=='Quotation'"><span>Quotation To:</span><span style="padding-left:0px;font-size:11px">اقتباس ل</span></div>
                                    <div class="col-4" style="font-weight:normal;font-size:14px" v-else-if="formName=='SaleOrder'"><span>SaleOrder To:</span><span style="padding-left:0px;font-size:11px">أمر بيع ل</span></div>
                                    <div class="col-4" style="font-weight:normal;font-size:14px" v-else><span>Invoice To:</span><span style="padding-left:2px;font-size:12px">الفاتورةإلى</span></div>
                                    <div class="col-8" style="padding-left:0px !important;font-size:15px !important; ">
                                        <span v-if="list.cashCustomer != null" style="padding-left: 0px !important; font-weight: bold;">{{list.cashCustomer}}</span> <span v-else style="font-weight:bold;">{{list.customerNameEn}} {{ list.customerNameAr}}</span>
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-4" style="font-weight:normal"><span>VAT #</span><span style="padding-left:12px;font-size:12px !important">الرقم الضربی</span></div>
                                    <div class="col-8" style="padding: 0px !important; font-size: 15px !important; font-weight: bold;">
                                        {{list.customerVat}}
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-4" style="font-weight:normal"><span>Address</span><span style="padding-left:12px;font-size:12px !important">العنوان </span></div>
                                    <div class="col-8" style="padding: 0px !important; font-size: 15px !important; font-weight: bold;">
                                        {{list.customerAddressWalkIn}}
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-4" style="font-weight:normal;font-size:14px !important"><span>Contact No</span><span style="padding-left:1px;font-size:12px !important">رقم الاتصال</span></div>
                                    <div class="col-8" style="padding: 0px !important; font-size: 15px !important; font-weight: bold;">
                                        {{list.mobile}}
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-4" style="font-weight:normal"><span>Email.</span><span style="padding-left:2px;font-size:12px !important">البريد الإلكتروني</span></div>
                                    <div class="col-8" style="padding: 0px !important; font-size: 15px !important; font-weight: bold;">
                                        {{list.email}}
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-3" style="font-weight:normal" v-if="formName=='Quotation'"><span>Quotation for <br />اقتباس لـ</span></div>
                                    <div class="col-3" style="font-weight:normal" v-else-if="formName=='SaleOrder'"><span>Sale Order for <br /> أمر بيع لـ</span></div>
                                    <div class="col-3" style="font-weight:normal" v-else><span>Invoice for <br />الــفـاتــورة لـ</span></div>
                                    <div class="col-9" style="padding: 0px !important; font-size: 12px !important; font-weight: bold;">
                                        {{list.description}}
                                    </div>
                                </div>
                            </div>

                            <div class="col-3 " style="font-weight:bold;background-color:white !important">
                                <div class="row" style="padding-top:20px !important;" v-if="list.poNumber!='' && list.poNumber!=null">

                                    <div class=" col-12 text-center " style="border:0.5px solid black">
                                        <span>PO No.</span> <span style="font-size:12px !important;">الرقم الطلب</span><br />
                                        <span>{{list.poNumber}}</span><br />
                                        <span>Date</span><span>التاريخ </span><br />
                                        <span>{{getDate(list.poDate)}}</span><br />
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div class=" col-5  text-right" style="background-color:white !important">
                        <p style="font-size:16px;color:black !important;margin-bottom:5px !important;"> VAT No.<span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>  </p>
                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                        <p style="font-size:15px;color:black !important;margin-bottom:0; margin-top:10px !important">
                            <span style="font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                            <span style="font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                            <span style="font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>

                        </p>

                        <p style="font-size:15px;color:black !important;margin-bottom:0;font-weight:bold" v-if="formName=='Quotation' || formName=='SaleOrder'">


                        </p>

                        <p style="font-size:15px;color:black !important;margin-bottom:0;font-weight:bold" v-else>
                            <span v-if="b2b && b2c">
                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                    <span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span>
                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                </span>
                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                </span>
                                <span v-else>
                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                </span>
                            </span>

                            <span v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                            <span v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                            <span v-else><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                        </p>
                        <p style=" font-size: 15px; color: #FFFFFF !important; background-color: #F46E0B !important; margin-bottom: 0 !important; font-weight: bold; " v-if="formName=='Quotation' ">
                            <span>Date </span>{{list.date}} <span>  التاريخ</span> <br />
                            <span style="font-size:20px !important;font-weight:bold !important;">Quotation No.&nbsp;&nbsp;&nbsp;  الرقم عرض الأسعار</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{list.registrationNo}}
                        </p>
                        <p style=" font-size: 15px; color: #FFFFFF !important; background-color: #E5A72E !important; margin-bottom: 0 !important; font-weight: bold; " v-else-if="formName=='SaleOrder' ">
                            <span>Date </span>{{list.date}} <span>  التاريخ</span> <br />
                            <span style="font-size:19px !important;font-weight:bold !important;">Sale Order No.&nbsp;&nbsp;&nbsp;  الرقم طلب المبيعات</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{list.registrationNo}}
                        </p>
                        <p style="font-size: 15px; color: black !important; margin-bottom: 0 !important; font-weight: bold;" v-else>
                            <span>Date </span>{{list.date}} <span>التاريخ  </span> <br />
                            <span>Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{list.registrationNo}}
                        </p>

                        <div class="row  " style="background-color:white !important; margin-top:10px !important;" v-if="formName=='Quotation' || formName=='SaleOrder'">
                            <div class=" col-6" style="background-color:white !important">

                            </div>
                            <div class=" col-6 text-right" style="background-color:white !important">
                                <div v-if="list.warrantyLogoPath!='' && list.warrantyLogoPath!=null && list.warrantyLogoPath!=undefined">
                                    <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width:100%; height:120px;">

                                </div>

                            </div>




                        </div>
                        <div class="row  " style="background-color:white !important; margin-top:10px !important;" v-else>
                            <div class="col-2"></div>
                            <div class=" col-6 text-right" style="background-color:white !important">
                                <div v-if="list.warrantyLogoPath!='' && list.warrantyLogoPath!=null && list.warrantyLogoPath!=undefined">
                                    <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width:100%; height:120px;">

                                </div>

                            </div>
                            <div class=" col-4 text-right" style="background-color:white !important">
                                <vue-qrcode v-bind:value="qrValue" style="width:120px;" />

                            </div>

                        </div>


                    </div>
                </div>


                <div class="row mt-2 " style="background-color:white !important">
                    <div class="col-md-12 " style="background-color:white !important">
                        <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:180mm;background-color:white !important;margin-bottom:0px !important">
                            <tr class="heading" style="font-size:13px !important;">
                                <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #63666A; ">#</th>
                                <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #63666A; ">Product Name <br /> اسم الصنف</th>
                                <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #63666A; ">Model/Style <br /> رقم الموديل</th>
                                <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #63666A; ">Qty <br /> الكمية </th>
                                <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #63666A; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #63666A; ">U.Price <br /> سعرالوحدة</th>
                                <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #63666A; ">Total Price <br /> الاجمالي </th>
                            </tr>

                            <template v-for="(item, index) in list.saleItems" style="background-color: white !important; ">
                                <tr style="font-size: 15px; font-weight: bold; height: 60px;" v-if="index< 10" v-bind:key="item.id">
                                    <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                    <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 10px;">{{item.productName}} <br /> <span style="font-size: 10px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                    <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                    <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                    <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                    <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                    <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                    <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                    <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                </tr>
                            </template>

                            <tr style="font-size:15px;font-weight:bold; height:60px;" v-for="(item, index) in emptyListCount" v-bind:key="index">
                                <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                <td class="text-left" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 10px; color: white !important"><br /> <span style="font-size: 13px; font-weight: bold"></span></td>
                                <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-if="isMultiUnit=='true'"></td>
                                <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                            </tr>

                            <tr style="font-weight:bold;">
                                <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0.5px solid #63666A; color: #FFFFFF !important; background-color: #F46E0B !important; font-weight: bolder !important; " colspan="2">{{headerFooters.bankIcon1}} Bank Details / {{headerFooters.bankAccount1}}</td>
                                <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                <td class="text-left" style="color:black !important;font-size:16px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3"> <span class="float-right">Total Amount/المبلغ الإجمالي</span> </td>
                                <td class="text-center" style="color: black !important; background-color: #BEBFC0 !important; font-size: 16px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(calulateTotalExclVAT - calulateTotalInclusiveVAT).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                            </tr>
                            <tr style="font-weight:bold;">
                                <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;" colspan="2">Terms & conditions   الأحكام والشروط</td>

                                <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                <td class="text-right" style="color:black !important;font-size:16px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Discount/الخصم</td>
                                <td class="text-center" style="color: black !important; background-color: #FFDE16 !important; font-size: 16px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(calulateDiscountAmount).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                            </tr>

                            <tr style="font-weight:bold;">
                                <td class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                    <p style="padding:0px !important;margin:0px !important; ">
                                        <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                                        <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                                    </p>
                                </td>


                                <td class="text-right" style="color:black !important;font-size:14px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Total After Discount/المجموع بعد الخصم</td>
                                <td class="text-center" style="color: black !important; background-color: #E5A72E !important; font-size: 16px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(calulateTotalExclVAT-calulateDiscountAmount).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                            </tr>
                            <tr style="font-weight:bold;">

                                <td class="text-right" style="color:black !important;font-size:14px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">VAT 15%  /ضريبة القيمة المضاف</td>
                                <td class="text-center" style="color: black !important; background-color: #D67728 !important; font-size: 16px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(calulateTotalVAT).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                            </tr>
                            <tr style="font-weight:bold;">
                                <td class="text-right" style="color:black !important;font-size:14px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Total with Tax / المجموع مع الضرائب</td>
                                <td class="text-center" style="color: black !important; background-color: #969798 !important; font-size: 16px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(calulateNetTotal - (calulateDiscountAmount + calulateBundleAmount)).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                            </tr>

                            <tr>
                                <td colspan="3" style="border:0 !important"></td>
                                <td style="border:0 !important"></td>
                            </tr>
                            <tr>
                                <td colspan="3" style="border:0 !important"></td>
                                <td style="border:0 !important"></td>
                            </tr>


                        </table>
                    </div>
                </div>

                <footer style="height: 40mm !important; ">
                    <div class="row " style="background-color:white !important" v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:15px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:40mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>
                </footer>
            </div>


        </div>
        <div class="col-1"></div>
    </div>
</template>

<script>
    import clickMixin from '@/Mixins/clickMixin'
    import moment from "moment";
    import VueQrcode from 'vue-qrcode'
    import VueBarcode from 'vue-barcode';

    export default {
        components: {
            VueQrcode,
            'barcode': VueBarcode,
        },
        mixins: [clickMixin],
        data: function () {
            return {
                b2b: false,
                b2c: false,
                taxInvoiceLabel: "",
                formName: "",
                taxInvoiceLabelAr: '',
                simplifyTaxInvoiceLabel: '',
                simplifyTaxInvoiceLabelAr: '',

                currency: "",
                qrValue: "",
                isHeaderFooter: '',
                invoicePrint: '',
                IsDeliveryNote: '',
                arabic: '',
                english: '',
                userName: '',
                emptyListCount: 0,
                indexCount: 0,
                page: 0,
                isMultiUnit: '',
                list: {
                    number: 0,
                    listItemTotal: [],
                    registrationNo: '',
                    date: '',
                    dueDate: '',
                    companyName: '',
                    companyPhoneNo: '',
                    companyAddress: '',
                    discountAmount: '',
                    cashCustomer: '',
                    creditCustomer: '',
                    customerPhoneNo: '',
                    customerAddress: '',
                    paymentMethod: '',
                    paymentMethodNo: '',
                    invocieType: '',
                    saleItems:
                        [

                        ]
                },

                itemTotal: 0,
                render: 0,
                headerFooters: {
                    footerEn: '',
                    footerAr: '',
                    company: ''
                },
                htmlData: {
                    htmlString: ''
                }
            }
        },
        filters: {
            toWords: function (value) {
                var converter = require('number-to-words');
                if (!value) return ''
                return converter.toWords(value);
            }
        },
        computed: {
            calulateTotalQty: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + (Number((c.quantity) || 0) > 0 ? Number((c.quantity) || 0) : 0) }, 0)
            },
            calulateNetTotal: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + (c.isFree ? 0 : Number((c.total + c.includingVat) || 0)) }, 0)
            },
            calulateTotalExclVAT: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + (c.isFree ? 0 : Number((c.total) || 0)) }, 0)
            },
            calulateTotalVAT: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + (c.isFree ? 0 : Number((c.includingVat + c.inclusiveVat) || 0)) }, 0)
            },
            calulateTotalInclusiveVAT: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + (c.isFree ? 0 : Number((c.inclusiveVat) || 0)) }, 0)
            },
            calulateDiscountAmount: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + (c.isFree ? 0 : Number(c.discountAmount || 0)) }, 0)
            },
            calulateBundleAmount: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + (c.isFree ? 0 : Number(c.bundleAmount || 0)) }, 0)
            }
        },
        methods: {
            saleReturnInvoice: function (data) {
                if (this.formName == 'SaleReturn')
                    return '-' + (data)
                else
                    return data
            },
            getDate: function (x) {
                if (x == null || x == undefined)
                    return '';
                else
                    return moment(x).format('l');
            },
            calulateDiscountAmount1: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + (c.isFree ? 0 : Number(c.discountAmount || 0)) }, 0)
            },
            calulateBundleAmount1: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + (c.isFree ? 0 : Number(c.bundleAmount || 0)) }, 0)
            },
            calulateNetTotalWithVAT: function () {
                var total = this.list.saleItems.reduce(function (a, c) { return a + (c.isFree ? 0 : Number((c.total + c.includingVat) || 0)) }, 0);
                var grandTotal = parseFloat(total) - (this.calulateDiscountAmount1() + this.calulateBundleAmount1())
                return (parseFloat(grandTotal).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"));
            },
            calulateTotalVATofInvoice: function () {
                var total = this.list.saleItems.reduce(function (a, c) { return a + (c.isFree ? 0 : Number((c.includingVat + c.inclusiveVat) || 0)) }, 0);
                return (parseFloat(total).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"));
            },

            GetTLVForValue: function (tagNumber, tagValue) {
                var tagBuf = Buffer.from([tagNumber], 'utf-8')
                var tagValueLenBuf = Buffer.from([tagValue.length], 'utf-8')
                var tagValueBuf = Buffer.from(tagValue, 'utf-8')
                var bufsArray = [tagBuf, tagValueLenBuf, tagValueBuf]
                return Buffer.concat(bufsArray)
            },
            goToSale: function () {

                if (this.formName == 'SaleReturn') {
                    this.$router.push({
                        path: '/ServiceSaleReturn',
                       
                    });
                }
                else {
                    this.$router.push({
                        path: '/SaleService',
                        query: {
                            data: 'AddSaleService'
                        }
                    });
                }
            },
        },
        mounted: function () {
            this.formName = this.$route.query.formName;
            this.b2b = localStorage.getItem('b2b') == 'true' ? true : false;
            this.b2c = localStorage.getItem('b2c') == 'true' ? true : false;

            this.isMultiUnit = localStorage.getItem('IsMultiUnit');
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.invoicePrint = localStorage.getItem('InvoicePrint');
            this.isHeaderFooter = localStorage.getItem('IsHeaderFooter');
            this.IsDeliveryNote = localStorage.getItem('IsDeliveryNote');
            this.userName = localStorage.getItem('FullName');
            this.currency = localStorage.getItem('currency');
            this.taxInvoiceLabel = localStorage.getItem('taxInvoiceLabel');
            this.taxInvoiceLabelAr = localStorage.getItem('taxInvoiceLabelAr');
            this.simplifyTaxInvoiceLabel = localStorage.getItem('simplifyTaxInvoiceLabel');
            this.simplifyTaxInvoiceLabelAr = localStorage.getItem('simplifyTaxInvoiceLabelAr');

            var root = this;
            if (this.$route.query.printDetails.saleItems.length > 0) {

                this.list = this.$route.query.printDetails;
                this.headerFooters = this.$route.query.headerFooter;
                var sellerNameBuff = root.GetTLVForValue('1', this.headerFooters.company.nameEnglish)
                var vatRegistrationNoBuff = root.GetTLVForValue('2', this.headerFooters.company.vatRegistrationNo)
                var timeStampBuff = root.GetTLVForValue('3', this.list.date)
                var totalWithVat = root.GetTLVForValue('4', this.calulateNetTotalWithVAT())
                var totalVat = root.GetTLVForValue('5', this.calulateTotalVATofInvoice())
                var tagArray = [sellerNameBuff, vatRegistrationNoBuff, timeStampBuff, totalWithVat, totalVat]
                var qrCodeBuff = Buffer.concat(tagArray)
                root.qrValue = qrCodeBuff.toString('base64')

                var count = this.$route.query.printDetails.saleItems.length;
                this.page = Math.ceil(count / 2);

                if (count <= 10) {
                    this.emptyListCount = 10 - count;
                    this.indexCount = 10 - this.emptyListCount;

                }
                this.list.date = moment().format('DD MMM YYYY');

            }
        },

    }
</script>
<style>
    .page {
        background-color: white !important;
    }

    .your-background:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: -1;
        border-bottom: 1000px solid #eee; /* Make it fit your needs */
    }
</style>


