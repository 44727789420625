<template>
    <div class="row" v-if="isValid('CanViewUserPermission')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
        <div class="col-lg-12 col-sm-12" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="accordion" role="tablist">
                
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.accordion-1 variant="primary">{{ $t('ThePermissions.UserAndPermissions') }}</b-button>
                    </b-card-header>
                    <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <div class="">
                                <div>
                                    <router-link :to="'/UserSetup'">
                                        <a href="javascript:void(0)" class="btn btn-outline-primary  "><i class="fas fa-arrow-circle-left fa-lg"></i> </a>
                                    </router-link>
                                </div>
                                <table class="table table-striped table-hove">
                                    <thead class="">
                                        <tr>
                                            <th>#</th>
                                            <th style="width: 60%">
                                                {{ $t('ThePermissions.RoleName') }}
                                            </th>
                                            <th>
                                                {{ $t('ThePermissions.UserAndPermissions') }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(roles,index) in resultQuery" v-bind:key="roles.id">
                                            <td>
                                                {{index+1}}
                                            </td>

                                            <td style="width: 60%">
                                                <strong>
                                                    <a href="javascript:void(0)">{{roles.name}}</a>
                                                </strong>
                                            </td>
                                            <td>
                                                <button class="btn btn-outline-primary  btn-icon mr-2" v-if="isValid('CanViewUserPermission')" @click="addRoleToUsers(roles.id, roles.name)" data-toggle="tooltip" data-placement="left" title="Assign User">
                                                    <i class="nc-icon nc-single-02"></i>
                                                </button>
                                                <button class="btn btn-outline-info  btn-icon" v-if="isValid('CanViewUserPermission')" @click="addPermissionsToRoles(roles.id, roles.name)" data-toggle="tooltip" data-placement="right" title="Assign Permission">
                                                    <i class="nc-icon nc-lock-circle-open"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
        </div>
        <assignRoleToUsers :roleDetails="roleDetails"
                           :show="show"
                           v-if="show"
                           @close="show = false"
                           :type="type" />

        <assignPermissionsToRole :roleDetails="roleDetailsForPermissions"
                                 :show="showPermissions"
                                 v-if="showPermissions"
                                 @close="showPermissions = false"
                                 :type="typePermissions" />
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>

<script>
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        data: function () {
            return {
                searchQuery: '',
                roleslist: [],
                roleDetails: [],
                roleDetailsForPermissions: [],
                type: '',
                show: false,
                typePermissions: '',
                showPermissions: false,
            }
        },
        computed: {
            resultQuery: function () {
                var root = this;
                if (this.searchQuery) {
                    return this.roleslist.filter((roles) => {
                        return root.searchQuery.toLowerCase().split(' ').every(v => roles.name.toLowerCase().includes(v))
                    })
                } else {
                    return root.roleslist;
                }
            },
        },
        methods: {
            addRoleToUsers: function (id, name) {
                this.roleDetails = {
                    roleId: id,
                    name: name,
                    userId: '',
                    id: '00000000-0000-0000-0000-000000000000'
                }
                this.show = !this.show;
                this.type = "Add";
            },
            GetListOfPermission: function (roleId,name) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Company/GetListOfPermission?roleId=' + roleId, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {
                        root.$router.push({ path: '/NoblePermissions', query: { data: response.data, roleName:name } })
                    }
                });
            },
            addPermissionsToRoles: function (id, name) {
                this.GetListOfPermission(id,name);
               
                console.log(id)
                console.log(name)
                //this.roleDetailsForPermissions = {
                //    roleId: id,
                //    name: name,
                //    allowAll: false,
                //    removeAll: false,
                //    moduleId: '',
                //    id: '00000000-0000-0000-0000-000000000000'
                //}
                //this.showPermissions = !this.showPermissions;
                //this.typePermissions = "Add";
            },
            GetRolesData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Company/NobleRolesList?isActive=true', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {
                        root.$store.dispatch('GetRoleList', response.data.nobleRoleModel);
                        root.roleslist = response.data.nobleRoleModel;
                    }
                });
            }
        },
        created: function () {
            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            this.GetRolesData();
        }
    }
</script>