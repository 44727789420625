<template>
    <div class="row" v-if="((isValid('CanAddPettyCash') || isValid('CanDraftPettyCash')|| isValid('CanRejectPettyCash')|| isValid('CanEditPettyCash')) && formName=='PettyCash') || ((isValid('CanAddCPR') || isValid('CanDraftCPR')|| isValid('CanRejectCPR')|| isValid('CanEditCPR')) && formName=='BankReceipt') || ((isValid('CanAddSPR') || isValid('CanDraftSPR')|| isValid('CanRejectSPR')|| isValid('CanEditSPR')) && formName=='BankPay')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
        <div class="col-lg-10 col-md-12  ml-auto mr-auto" v-bind:style="$i18n.locale == 'ar' ? languageChange('en') : languageChange('ar')">
            <div class=" row mb-3 ">
                <div class="col-lg-9  ">

                    <span v-if="paymentVoucher.id != '00000000-0000-0000-0000-000000000000'  && formName=='BankReceipt'"> <span class="MainLightHeading">{{ $t('AddPaymentVoucher.UpdateCustomerPayReceipt')}} - </span><span class="DayHeading">{{ paymentVoucher.voucherNumber }}</span></span>
                    <span v-if="paymentVoucher.id != '00000000-0000-0000-0000-000000000000' && formName=='BankPay'"> <span class="MainLightHeading">{{ $t('AddPaymentVoucher.UpdateSupplierPaymentReceipt')}} - </span><span class="DayHeading">{{ paymentVoucher.voucherNumber }}</span></span>
                    <span v-if="paymentVoucher.id == '00000000-0000-0000-0000-000000000000' && formName=='BankReceipt'"> <span class="MainLightHeading">{{ $t('AddPaymentVoucher.AddCustomerPayReceipt')}} - </span><span class="DayHeading">{{ paymentVoucher.voucherNumber }}</span></span>
                    <span v-if="paymentVoucher.id == '00000000-0000-0000-0000-000000000000'  && formName=='BankPay'"> <span class="MainLightHeading">{{ $t('AddPaymentVoucher.AddSupplierPaymentReceipt')}} - </span><span class="DayHeading">{{ paymentVoucher.voucherNumber }}</span></span>
                    <span v-if="paymentVoucher.id == '00000000-0000-0000-0000-000000000000'  && formName=='PettyCash'"> <span class="MainLightHeading">{{ $t('AddPaymentVoucher.AddPettyCash')}} - </span><span class="DayHeading">{{ paymentVoucher.voucherNumber }}</span></span>
                    <span v-if="paymentVoucher.id != '00000000-0000-0000-0000-000000000000'  && formName=='PettyCash'"> <span class="MainLightHeading">{{ $t('AddPaymentVoucher.UpdatePettyCash')}}  - </span><span class="DayHeading">{{ paymentVoucher.voucherNumber }}</span></span>
                </div>
                <div class="col-lg-3  ">

                </div>
            </div>
            <div class="card">
                <div class="card-body" :key="render">

                    <div class="invoice-area">

                        <div class="row">
                            <div class="col-lg-12">

                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                        <label>{{ $t('AddPaymentVoucher.Date') }} :<span class="LabelColour"> *</span></label>
                                        <div class="form-group" v-bind:class="{ 'has-danger': $v.paymentVoucher.date.$error}">
                                            <datepicker v-model="$v.paymentVoucher.date.$model"></datepicker>
                                            <span v-if="$v.paymentVoucher.date.$error" class="error">
                                                <span v-if="!$v.paymentVoucher.date.required">{{formName}}  {{ $t('AddPaymentVoucher.DateRequired') }}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-6" v-if="formName=='PettyCash'">
                                        <label>
                                            {{ $t('AddPaymentVoucher.PaymentMode') }}:
                                            <span class="LabelColour"> *</span>
                                        </label>

                                        <div class="form-group">

                                            <multiselect v-if="($i18n.locale == 'en' ||isLeftToRight()) " disabled v-model="paymentVoucher.paymentMode" v-on:input="GetAccount(paymentVoucher.paymentMode)" :options="['Cash', 'Bank']" :show-labels="false" placeholder="Select Type">
                                            </multiselect>
                                            <multiselect v-else disabled v-model="paymentVoucher.paymentMode" v-on:input="GetAccount(paymentVoucher.paymentMode)" :options="[ 'السيولة النقدية', 'مصرف']" :show-labels="false" v-bind:placeholder="$t('AddPaymentVoucher.SelectOption')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                            </multiselect>

                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-6" v-else>
                                        <label>
                                            {{ $t('AddPaymentVoucher.PaymentMode') }}:
                                            <span class="LabelColour"> *</span>
                                        </label>

                                        <div class="form-group">

                                            <multiselect v-if="($i18n.locale == 'en' ||isLeftToRight()) " :disabled="isTemporaryCashIssue" v-model="paymentVoucher.paymentMode" v-on:input="GetAccount(paymentVoucher.paymentMode)" :options="['Cash', 'Bank','Advance']" :show-labels="false" placeholder="Select Type">
                                            </multiselect>
                                            <multiselect v-else :disabled="isTemporaryCashIssue" v-model="paymentVoucher.paymentMode" v-on:input="GetAccount(paymentVoucher.paymentMode)" :options="[ 'السيولة النقدية', 'مصرف','يتقدم']" :show-labels="false" v-bind:placeholder="$t('AddPaymentVoucher.SelectOption')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                            </multiselect>

                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                        <label>
                                            {{ $t('AddPaymentVoucher.PaymentType') }}:
                                            <span class="LabelColour" v-if="paymentVoucher.paymentMode=='Cash' || paymentVoucher.paymentMode=='السيولة النقدية' "></span>
                                            <span class="LabelColour" v-else> *</span>
                                        </label>



                                        <div class="form-group" v-if="paymentVoucher.paymentMode=='Cash' || paymentVoucher.paymentMode=='السيولة النقدية' ">

                                            <multiselect v-if="($i18n.locale == 'en' ||isLeftToRight()) " disabled v-model="paymentVoucher.paymentMethod" :options="['Cheque', 'Transfer','Deposit']" :show-labels="false" placeholder="Select Type">
                                            </multiselect>
                                            <multiselect v-else v-model="paymentVoucher.paymentMethod" disabled :options="[ 'التحقق من', 'تحويل','الوديعة']" :show-labels="false" v-bind:placeholder="$t('AddPaymentVoucher.SelectOption')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                            </multiselect>

                                        </div>
                                        <div class="form-group" v-else>

                                            <multiselect v-if="($i18n.locale == 'en' ||isLeftToRight()) " v-model="paymentVoucher.paymentMethod" :options="['Cheque', 'Transfer','Deposit']" :show-labels="false" placeholder="Select Type">
                                            </multiselect>
                                            <multiselect v-else v-model="paymentVoucher.paymentMethod" :options="[ 'التحقق من', 'تحويل','الوديعة']" :show-labels="false" v-bind:placeholder="$t('AddPaymentVoucher.SelectOption')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                            </multiselect>

                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                        <label v-if="paymentVoucher.paymentMode=='Cash' || paymentVoucher.paymentMode=='السيولة النقدية' ">
                                            {{ $t('AddPaymentVoucher.CashAccount') }}:
                                            <span class="LabelColour"> *</span>

                                        </label>
                                        <label v-else-if="paymentVoucher.paymentMode=='Bank' || paymentVoucher.paymentMode=='مصرف' ">
                                            {{ $t('AddPaymentVoucher.BankAccount') }}
                                            <span class="LabelColour"> *</span>

                                        </label>
                                        <label v-else-if="paymentVoucher.paymentMode=='Advance' || paymentVoucher.paymentMode=='يتقدم' ">
                                            Advance Account :
                                            <span class="LabelColour"> *</span>

                                        </label>
                                        <label v-else>
                                            {{ $t('AddPaymentVoucher.BankAccount') }}:
                                            <span class="LabelColour"> *</span>

                                        </label>
                                        <div class="form-group" v-bind:class="{ 'has-danger': $v.paymentVoucher.bankCashAccountId.$error}" v-if="paymentVoucher.paymentMode=='Cash' || paymentVoucher.paymentMode=='السيولة النقدية' " v-bind:key="randerAccount">
                                            <accountdropdown v-model="$v.paymentVoucher.bankCashAccountId.$model" v-on:input="GetBankOpeningBalance(paymentVoucher.bankCashAccountId)" :formName="CashPay" :isPurchase="formName=='BankReceipt'?false:true" :disabled="isTemporaryCashIssue"></accountdropdown>
                                            <span v-if="$v.paymentVoucher.bankCashAccountId.$error" class="error">
                                                <span v-if="!$v.paymentVoucher.bankCashAccountId.required">{{formName}}  {{ $t('AddPaymentVoucher.AccountRequired') }}</span>
                                            </span>
                                        </div>
                                        <div class="form-group" v-bind:class="{ 'has-danger': $v.paymentVoucher.bankCashAccountId.$error}" v-else-if="paymentVoucher.paymentMode=='Bank' || paymentVoucher.paymentMode=='مصرف' " v-bind:key="randerAccount">
                                            <accountdropdown v-on:input="GetBankOpeningBalance(paymentVoucher.bankCashAccountId)" v-model="$v.paymentVoucher.bankCashAccountId.$model" :formName="BankPay" :isPurchase="formName=='BankReceipt'?false:true"></accountdropdown>
                                            <span v-if="$v.paymentVoucher.bankCashAccountId.$error" class="error">
                                                <span v-if="!$v.paymentVoucher.bankCashAccountId.required">{{formName}}  {{ $t('AddPaymentVoucher.AccountRequired') }}</span>
                                            </span>
                                        </div>
                                        <div class="form-group" v-bind:class="{ 'has-danger': $v.paymentVoucher.bankCashAccountId.$error}" v-else>
                                            <accountdropdown v-model="$v.paymentVoucher.bankCashAccountId.$model" v-on:input="GetBankOpeningBalance(paymentVoucher.bankCashAccountId)" :formName="BankPay" :isPurchase="formName=='BankReceipt'?false:true"></accountdropdown>
                                            <span v-if="$v.paymentVoucher.bankCashAccountId.$error" class="error">
                                                <span v-if="!$v.paymentVoucher.bankCashAccountId.required">{{formName}}  {{ $t('AddPaymentVoucher.AccountRequired') }}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                        <label v-if="paymentVoucher.paymentMode=='Cash' || paymentVoucher.paymentMode=='السيولة النقدية' ">
                                            Cash Account Running Balance:

                                        </label>
                                        <label v-else-if="paymentVoucher.paymentMode=='Bank' || paymentVoucher.paymentMode=='مصرف' ">
                                            Bank Account Running Balance

                                        </label>
                                        <label v-else-if="paymentVoucher.paymentMode=='Advance' || paymentVoucher.paymentMode=='يتقدم' ">
                                            Advance Account  Running Balance :

                                        </label>
                                        <label v-else>
                                            Bank Account Running Balance

                                        </label>
                                        <div class="form-group">
                                            <input disabled v-model="runningBalanceCashBank" class="form-control" type="text" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                        <label v-if="formName=='CashReceipt' || formName=='BankReceipt' ">
                                            {{ $t('AddPaymentVoucher.CustomerAccount') }}:
                                            <span class="LabelColour"> *</span>

                                        </label>
                                        <label v-if="formName=='PettyCash' ">
                                            {{ $t('AddPaymentVoucher.Account') }}:
                                            <span class="LabelColour"> *</span>

                                        </label>
                                        <label v-if="formName=='BankPay' || formName=='CashPay'">
                                            {{ $t('AddPaymentVoucher.SupplierAccount') }}:
                                            <span class="LabelColour"> *</span>

                                        </label>
                                        <div class="form-group" v-bind:class="{ 'has-danger': $v.paymentVoucher.contactAccountId.$error}" v-if=" formName=='PettyCash'">
                                            <accountdropdown v-on:input="enableInvoiceDropdown" v-model="$v.paymentVoucher.contactAccountId.$model" :formName="'PettyCashAccount'"></accountdropdown>
                                            <span v-if="$v.paymentVoucher.contactAccountId.$error" class="error">
                                                <span v-if="!$v.paymentVoucher.contactAccountId.required">{{formName}}  {{ $t('AddPaymentVoucher.AccountRequired') }}</span>
                                            </span>
                                        </div>
                                        <div class="form-group" v-bind:class="{ 'has-danger': $v.paymentVoucher.contactAccountId.$error}" v-else>
                                            <accountdropdown v-on:input="enableInvoiceDropdown" :formNames="formName" v-model="$v.paymentVoucher.contactAccountId.$model"></accountdropdown>
                                            <span v-if="$v.paymentVoucher.contactAccountId.$error" class="error">
                                                <span v-if="!$v.paymentVoucher.contactAccountId.required">{{formName}}  {{ $t('AddPaymentVoucher.AccountRequired') }}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                        <label>
                                            {{ $t('AddPaymentVoucher.RunningBalance') }}:
                                            <span class="LabelColour"> *</span>

                                        </label>
                                        <div class="form-group">

                                            <input disabled v-model="runningBalance" class="form-control" type="text" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" />
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-4 col-sm-6" v-if="formName=='CashReceipt' || formName=='BankReceipt'">
                                        <label>
                                            {{ $t('AddPaymentVoucher.SaleInvoice') }}
                                        </label>
                                        <div class="form-group">
                                            <sale-invoice-dropdown ref="saleInvoiceDropdown" v-model="paymentVoucher.saleInvoice" v-bind:isExpense="true" v-on:input="getSaleNetAmount" :key="saleInvoiceRander" v-bind:isCredit="true" :contactId="paymentVoucher.contactAccountId" :isDisabled="isShow" />

                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-6" v-if="formName=='BankPay' || formName=='CashPay'">
                                        <label>
                                            {{ $t('AddPaymentVoucher.PurchaseInvoice') }}
                                        </label>
                                        <div class="form-group">
                                            <purchaseinvoicedropdown v-on:input="getPurchaseNetAmount" ref="purchaseInvoiceDropdown" :values="paymentVoucher.purchaseInvoice" v-model="paymentVoucher.purchaseInvoice" v-bind:isExpense="true" :key="purchaseInvoiceRander" :supplierAccountId="paymentVoucher.contactAccountId" :isDisabled="isShow" />

                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-6" v-if="paymentVoucher.paymentMethod=='Cheque' || paymentVoucher.paymentMethod=='التحقق من' ">
                                        <label>
                                            {{ $t('AddPaymentVoucher.ChequeNumber') }}
                                        </label>
                                        <div class="form-group">
                                            <input v-model="paymentVoucher.chequeNumber" class="form-control" type="text" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                        <label>
                                            {{ $t('AddPaymentVoucher.Amount') }} :
                                            <span class="LabelColour"> *</span>
                                        </label>
                                        <div class="form-group">
                                            <my-currency-input v-model="paymentVoucher.amount" @input="zeroPrice(paymentVoucher.amount)"></my-currency-input>
                                            <!--<input v-model="paymentVoucher.amount" class="form-control" type="number" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" />-->
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-6" v-if=" formName=='PettyCash' ">
                                        <label>
                                            {{ $t('AddPaymentVoucher.PattyCashType') }}
                                        </label>
                                        <div class="form-group">
                                            <multiselect v-if="($i18n.locale == 'en' ||isLeftToRight()) " v-model="paymentVoucher.pettyCash" :options="['Temporary', 'General', 'Advance']" :show-labels="false" placeholder="Select Type">
                                            </multiselect>
                                            <multiselect v-else v-model="paymentVoucher.pettyCash" :options="['مؤقت', 'عام', 'تقدم']" :show-labels="false" v-bind:placeholder="$t('AddPaymentVoucher.SelectOption')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                            </multiselect>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xm-12 col-sm-12 col-md-12 col-lg-12">
                                        <label>
                                            {{ $t('AddPaymentVoucher.Narration') }} / {{$t('AddPaymentVoucher.Remarks')}}
                                        </label>
                                        <div class="form-group">
                                            <textarea v-model="paymentVoucher.narration" class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                    </div>

                    <div>
                        <button class="btn btn-danger  float-right" v-on:click="onCancel">  {{ $t('AddPaymentVoucher.Cancel') }}</button>
                        <div v-if="paymentVoucher.id=='00000000-0000-0000-0000-000000000000'">
                            <button class="btn btn-primary mr-2 float-left" v-on:click="Attachment()" v-if="(isValid('CanAddPettyCashAttachment') && formName=='PettyCash') || (isValid('CanAddCPRAttachment') && formName=='BankReceipt') || (isValid('CanAddAttachment') && formName=='BankPay')">
                                {{ $t('AddSaleOrder.Attachment') }}
                            </button>
                            <button type="button" class="btn btn-primary  float-right" v-bind:disabled="$v.paymentVoucher.$invalid || (isTemporaryCashIssue? (temporaryCashIssue < paymentVoucher.amount):false)" v-if=" (isValid('CanAddPettyCash') && formName=='PettyCash') || (isValid('CanAddCPR') && formName=='BankReceipt')  || (isValid('CanAddSPR') && formName=='BankPay')" v-on:click="SaveVoucher('Approved')"><i class="far fa-save"></i> {{ $t('AddPaymentVoucher.SaveandPost') }}</button>
                            <button type="button" class="btn btn-primary  float-right" v-bind:disabled="$v.paymentVoucher.$invalid" v-if="((isValid('CanDraftPettyCash')  && formName=='PettyCash') || (isValid('CanDraftCPR')  && formName=='BankReceipt') || (isValid('CanDraftSPR') && formName=='BankPay')) && !isTemporaryCashIssue" v-on:click="SaveVoucher('Draft')"><i class="far fa-save"></i>  {{ $t('AddPaymentVoucher.SaveasDraft') }}</button>
                        </div>
                        <div v-else>
                            <button class="btn btn-primary mr-2 float-left" v-on:click="Attachment()" v-if="(isValid('CanAddPettyCashAttachment') && formName=='PettyCash') || (isValid('CanAddCPRAttachment') && formName=='BankReceipt') || (isValid('CanAddAttachment') && formName=='BankPay')">
                                {{ $t('AddSaleOrder.Attachment') }}
                            </button>
                            <div v-if="paymentVoucher.approvalStatus ==3">
                                <button type="button" class="btn btn-primary  float-right" v-if=" (isValid('CanEditPettyCash') && formName=='PettyCash') || (isValid('CanEditCPR') && formName=='BankReceipt') || (isValid('CanEditSPR') && formName=='BankPay')" v-on:click="SaveVoucher('Approved')"><i class="far fa-save"></i> {{ $t('AddPaymentVoucher.SaveandPost') }}</button>
                            </div>
                            <div v-else>
                                <button type="button" class="btn btn-primary  float-right" v-if="(isValid('CanRejectPettyCash') && formName=='PettyCash') || (isValid('CanRejectCPR') && formName=='BankReceipt') || (isValid('CanRejectSPR') && formName=='BankPay')" v-on:click="SaveVoucher('Rejected')"><i class="far fa-save"></i> {{ $t('AddPaymentVoucher.SaveasReject') }}</button>
                                <button type="button" class="btn btn-primary  float-right" v-if=" (isValid('CanEditPettyCash') && formName=='PettyCash') || (isValid('CanEditCPR') && formName=='BankReceipt') || (isValid('CanEditSPR') && formName=='BankPay')" v-on:click="SaveVoucher('Approved')"><i class="far fa-save"></i> {{ $t('AddPaymentVoucher.SaveandPost') }}</button>
                                <button type="button" class="btn btn-primary  float-right" v-if=" (isValid('CanDraftPettyCash') && formName=='PettyCash') || (isValid('CanDraftCPR') && formName=='BankReceipt') || (isValid('CanDraftSPR') && formName=='BankPay')" v-on:click="SaveVoucher('Draft')"><i class="far fa-save"></i> {{ $t('AddPaymentVoucher.UpdateasDraft') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <bulk-attachment :attachmentList="paymentVoucher.attachmentList" :show="show" v-if="show" @close="attachmentSave" />
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import { required, minValue } from "vuelidate/lib/validators";
    import Multiselect from 'vue-multiselect'

    import moment from "moment";
    export default {
        mixins: [clickMixin],
        components: {
            Multiselect,
        },
        props: ['formName'],
        data: function () {
            return {
                temporaryCashIssue: 0,
                isTemporaryCashIssue: false,
                ispayable: true,
                render: 0,
                saleInvoiceRander: 0,
                purchaseInvoiceRander: 0,
                isShow: true,
                attachment: false,
                paymentVoucher: {
                    id: '00000000-0000-0000-0000-000000000000',
                    date: '',
                    voucherNumber: '',
                    chequeNumber: '',
                    narration: '',
                    paymentVoucherType: '',
                    amount: 0,
                    approvalStatus: 'Draft',
                    purchaseInvoice: '',
                    saleInvoice: '',
                    bankCashAccountId: '',
                    contactAccountId: '',
                    paymentMode: '',
                    paymentMethod: '',
                    userName: '',
                    temporaryCashIssueId: '',
                    attachmentList:[]
                },
                loading: false,
                type: '',
                isBank: true,
                voucherNumberRander: 0,
                language: 'Nothing',
                CashPay: 'CashPay',
                BankPay: 'BankPay',
                randerAccount: 0,
                disable: false,
                show: false,
                runningBalance: 0,
                runningBalanceCashBank: 0,
            }
        },
        

        validations: {
            paymentVoucher: {
                voucherNumber: {
                    required
                },
                date: {
                    required
                },
                bankCashAccountId: {
                    required
                },
                contactAccountId: {
                    required
                },
                amount: { minValue: minValue(1) }
            }
        },
        methods: {
            Attachment: function () {
                this.show = true;
            },

            attachmentSave: function (attachment) {
                this.paymentVoucher.attachmentList = attachment;
                this.show = false;
            },

            GetAccount: function (x) {

                
                if (x == 'السيولة النقدية' || x == 'Bank') {
                    this.randerAccount++;

                }
                else if (x == 'مصرف' || x == 'Cash') {
                    this.randerAccount++;
                }
                else if (x == 'يتقدم' || x == 'Advance') {
                    this.randerAccount++;
                }

            },
            DownloadAttachment(path) {
                

                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                var ext = path.split('.')[1];
                root.$https.get('/Contact/DownloadFile?filePath=' + path, { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'file.' + ext);
                        document.body.appendChild(link);
                        link.click();
                    });
            },

            uploadImage() {
                
                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                var file = null;

                file = this.$refs.imgupload1.files;

                var fileData = new FormData();
                for (var k = 0; k < file.length; k++) {
                    fileData.append("files", file[k]);
                }
                root.$https.post('/Company/UploadFilesAsync', fileData, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        

                        if (response.data != null) {

                            root.paymentVoucher.path = response.data;

                        }
                    },
                        function () {
                            this.loading = false;
                            root.$swal({
                                title: "Error!",
                                text: "Something went wrong",
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                        });
            },

            zeroPrice: function (x) {

                if (x == 0) {
                    this.disable = true;


                }
                else {
                    this.disable = false;
                }

            },
            GetBankOpeningBalance: function (id) {

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                var root = this
                this.$https.get('/Contact/GetCustomerRunningBalance?id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        
                        root.runningBalanceCashBank = parseFloat(response.data) >= 0 ? 'Dr ' + parseFloat(response.data) * +1 : 'Cr ' + parseFloat(response.data) * (-1);
                    }
                });

            },
            UpdateStatus: function (status) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                this.$https.post('/PaymentVoucher/UpdateStatusPaymentVoucher?id=' + this.paymentVoucher.id + '&approvalStatus=' + status, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.type == 'Edit') {
                        root.$swal({
                            title: "Updated!",
                            text: response.data.message.isAddUpdate,
                            type: 'success',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                            confirmButtonClass: "btn btn-success",
                            buttonsStyling: false
                        }).then(function (result) {
                            if (result) {

                                if (root.ispayable) {
                                    window.location.href = "/paymentVoucherList?formName=" + root.formName;
                                }
                            }
                        });

                    }
                    else if (response.data.message.id == '00000000-0000-0000-0000-000000000000') {
                        root.$swal({
                            title: 'Error!',
                            text: response.data.message.isAddUpdate,
                            type: 'error',
                            confirmButtonClass: "btn btn-info",
                            buttonsStyling: false
                        });
                    }

                }, function (value) {
                    root.$swal({
                        title: 'Error!',
                        text: value,
                        type: 'error',
                        confirmButtonClass: "btn btn-info",
                        buttonsStyling: false
                    });
                }
                ).catch(error => {

                    var customError = JSON.stringify(error.response.data.error);
                    root.$swal({
                        title: 'Error!',
                        text: customError,
                        type: 'error',
                        confirmButtonClass: "btn btn-info",
                        buttonsStyling: false
                    });
                    root.loading = false;
                });
            },
            getSaleNetAmount: function () {
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                var root = this
                var totalAmount = this.$refs.saleInvoiceDropdown.GetAmountOfSelected();
                this.$https.get('/Sale/GetRemainingInvoiceAmount?id=' + root.paymentVoucher.saleInvoice, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        
                        root.paymentVoucher.amount = parseFloat(totalAmount) - parseFloat(response.data);
                    }
                });
                
            },
            getPurchaseNetAmount: function () {
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                var root = this
                var totalAmount = this.$refs.purchaseInvoiceDropdown.GetAmountOfSelected();
                this.$https.get('/Sale/GetRemainingInvoiceAmount?id=' + root.paymentVoucher.purchaseInvoice, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {

                        root.paymentVoucher.amount = parseFloat(totalAmount) - parseFloat(response.data);
                    }
                });
            },
            enableInvoiceDropdown: function () {
                
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                var root = this
                this.$https.get('/Contact/GetCustomerRunningBalance?id=' + root.paymentVoucher.contactAccountId, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {

                        root.runningBalance = parseFloat(response.data) >= 0 ? 'Dr ' + parseFloat(response.data) * +1 : 'Cr ' + parseFloat(response.data) * (-1);
                    }
                });
               
                if (this.paymentVoucher.saleInvoice === undefined || this.paymentVoucher.saleInvoice === null) {
                    
                    this.paymentVoucher.saleInvoice = '00000000-0000-0000-0000-000000000000';
                }
                   
                if (this.paymentVoucher.purchaseInvoice === undefined || this.paymentVoucher.purchaseInvoice === null) {
                    
                    this.paymentVoucher.purchaseInvoice = '00000000-0000-0000-0000-000000000000';
                }
                   
                if (this.formName == 'CashReceipt' || this.formName == 'BankReceipt') {
                    this.isShow = false
                    this.saleInvoiceRander++;
                }
                else if (this.formName == 'BankPay' || this.formName == 'CashPay') {
                    this.isShow = false
                    this.purchaseInvoiceRander++;
                }

            },
            languageChange: function (lan) {
                if (this.language == lan) {

                    if (this.paymentVoucher.id == '00000000-0000-0000-0000-000000000000') {

                        var getLocale = this.$i18n.locale;
                        this.language = getLocale;

                        this.$router.go('/addPaymentVoucherformName?formName=' + this.formName);
                    }
                    else {

                        this.$swal({
                            title: (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Error!' : 'خطأ',
                            text: (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'You cannot Change language during update, otherwise your current page data will be lose!' : 'لا يمكنك تغيير اللغة أثناء التحديث ، وإلا ستفقد بيانات صفحتك الحالية!',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 4000,
                            timerProgressBar: true,
                        });
                    }
                }


            },

            GetAutoCodeGenerator: function (value) {
                
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/PaymentVoucher/AutoGenerateCode?paymentVoucherType=' + value, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {

                        root.paymentVoucher.voucherNumber = response.data;
                        root.voucherNumberRander++;

                    }
                });
            },

            SaveVoucher: function (x) {
                localStorage.setItem('active', x);

                if (this.$i18n.locale == 'ar') {
                    if (this.paymentVoucher.pettyCash == 'مؤقت') {
                        this.paymentVoucher.pettyCash = 1;
                    }
                    if (this.paymentVoucher.pettyCash == 'عام') {
                        this.paymentVoucher.pettyCash = 2;
                    }
                    if (this.paymentVoucher.pettyCash == 'تقدم') {
                        this.paymentVoucher.pettyCash = 3;
                    }
                    if (this.paymentVoucher.paymentMethod == 'التحقق من') {
                        this.paymentVoucher.paymentMethod = 1;
                    }
                    else if (this.paymentVoucher.paymentMethod == 'تحويل') {
                        this.paymentVoucher.paymentMethod = 2;
                    }
                    else if (this.paymentVoucher.paymentMethod == 'الوديعة') {
                        this.paymentVoucher.paymentMethod = 3;
                    }
                    else {
                        this.paymentVoucher.paymentMethod = 0;
                    }

                    if (this.paymentVoucher.paymentMode == 'السيولة النقدية') {
                        this.paymentVoucher.paymentMode = 0;
                    }
                    if (this.paymentVoucher.paymentMode == 'مصرف') {
                        this.paymentVoucher.paymentMode = 1;
                    } if (this.paymentVoucher.paymentMode == 'يتقدم') {
                        this.paymentVoucher.paymentMode = 5;
                    }



                }
                if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                    if (this.paymentVoucher.paymentMethod == 'Cheque') {
                        this.paymentVoucher.paymentMethod = 1;
                    }
                    else if (this.paymentVoucher.paymentMethod == 'Transfer') {
                        this.paymentVoucher.paymentMethod = 2;
                    }
                    else if (this.paymentVoucher.paymentMethod == 'Deposit') {
                        this.paymentVoucher.paymentMethod = 3;
                    }
                    else {
                        this.paymentVoucher.paymentMethod = 0;
                    }
                    if (this.paymentVoucher.paymentMode == 'Cash') {
                        this.paymentVoucher.paymentMode = 0;
                    }
                    if (this.paymentVoucher.paymentMode == 'Bank') {
                        this.paymentVoucher.paymentMode = 1;
                    } if (this.paymentVoucher.paymentMode == 'Advance') {
                        this.paymentVoucher.paymentMode = 5;
                    }

                }
                if (this.paymentVoucher.paymentMethod != 1) {
                    this.paymentVoucher.chequeNumber = '';
                }
                var root = this;
                var token = '';
                this.paymentVoucher.approvalStatus = x;
                this.paymentVoucher.userName = localStorage.getItem('LoginUserName');
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.paymentVoucher.date = this.paymentVoucher.date + " " + moment().format("hh:mm A");
                
                this.$https.post('/PaymentVoucher/AddPaymentVoucher', this.paymentVoucher, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.type == 'Add') {
                        
                        root.$swal({
                            title: "Saved!",
                            text: response.data.message.isAddUpdate,
                            type: 'success',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                            confirmButtonClass: "btn btn-success",
                            buttonsStyling: false
                        }).then(function (result) {
                            if (result) {
                                if (root.isTemporaryCashIssue) {
                                    root.$router.push({
                                        path: '/TemporaryCashIssue',
                                    })
                                }
                                else {
                                    if (root.ispayable) {
                                        window.location.href = "/addPaymentVoucher?formName=" + root.formName;
                                    }
                                }                                
                            }
                        });

                    }
                    else if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.type == 'Edit') {
                        root.$swal({
                            title: "Updated!",
                            text: response.data.message.isAddUpdate,
                            type: 'success',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                            confirmButtonClass: "btn btn-success",
                            buttonsStyling: false
                        }).then(function (result) {
                            if (result) {

                                if (root.ispayable) {
                                    root.$router.push({
                                        path: '/paymentVoucherList?formName=' + root.formName,
                                        query: {
                                            data: 'PaymentVoucherLists' + root.formName
                                        }
                                    })
                                //    window.location.href = "/paymentVoucherList?formName=" + root.formName;
                                }
                            }
                        });

                    }
                    else if (response.data.message.id == '00000000-0000-0000-0000-000000000000') {
                        root.$swal({
                            title: 'Error!',
                            text: response.data.message.isAddUpdate,
                            type: 'error',
                            confirmButtonClass: "btn btn-info",
                            buttonsStyling: false
                        });
                    }

                }, function (value) {
                    root.$swal({
                        title: 'Error!',
                        text: value,
                        type: 'error',
                        confirmButtonClass: "btn btn-info",
                        buttonsStyling: false
                    });
                }
                ).catch(error => {

                    var customError = JSON.stringify(error.response.data.error);
                    root.$swal({
                        title: 'Error!',
                        text: customError,
                        type: 'error',
                        confirmButtonClass: "btn btn-info",
                        buttonsStyling: false
                    });
                    root.loading = false;
                });
            },
            getpaymentVoucherDetails: function (paymentVoucherItem) {

                this.paymentVoucher = paymentVoucherItem;
            },
            onCancel: function () {
                if (((this.isValid('CanViewPettyCash') || this.isValid('CanDraftPettyCash')) && this.formName == 'PettyCash') || ((this.isValid('CanViewCPR') || this.isValid('CanDraftCPR')) && this.formName == 'BankReceipt') || ((this.isValid('CanViewSPR') || this.isValid('CanDraftSPR')) && this.formName == 'BankPay')) {
                    this.$router.push({
                        path: '/paymentVoucherList?formName=' + this.formName,
                        query: {
                            data: 'PaymentVoucherLists' + this.formName
                        }
                    })
                }
                else {
                    this.$router.go();
                }
                
            },
        },
        watch: {
            formName: function () {
                if (this.formName == 'BankPay') {
                    
                    if (this.$route.query.data == undefined) {
                        this.GetAutoCodeGenerator(this.formName);
                        this.paymentVoucher.paymentVoucherType = this.formName;
                    }
                    if (this.$route.query.data != undefined) {
                        this.attachment = true;
                        this.paymentVoucher = this.$route.query.data.message;
                        this.paymentVoucher.paymentVoucherType = 'BankPay';
                        this.GetBankOpeningBalance(this.paymentVoucher.bankCashAccountId);
                        this.enableInvoiceDropdown();
                        this.paymentVoucherDetails = this.$route.query.data.message.paymentVoucherDetails;
                        if (this.$i18n.locale == 'ar') {
                            if (this.paymentVoucher.paymentMethod == 1) {
                                this.paymentVoucher.paymentMethod = 'التحقق من';
                            }
                            else if (this.paymentVoucher.paymentMethod == 2) {
                                this.paymentVoucher.paymentMethod = 'تحويل';
                            }
                            else if (this.paymentVoucher.paymentMethod == 3) {
                                this.paymentVoucher.paymentMethod = 'الوديعة';
                            }
                            else {
                                this.paymentVoucher.paymentMethod = '';
                            }

                            if (this.paymentVoucher.paymentMode == 0) {
                                this.paymentVoucher.paymentMode = 'السيولة النقدية';
                            }
                            if (this.paymentVoucher.paymentMode == 1) {
                                this.paymentVoucher.paymentMode = 'مصرف';
                            }
                            if (this.paymentVoucher.paymentMode == 5) {
                                this.paymentVoucher.paymentMode = 'يتقدم';
                            }



                        }
                        if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                            if (this.paymentVoucher.paymentMethod == 1) {
                                this.paymentVoucher.paymentMethod = 'Cheque';
                            }
                            else if (this.paymentVoucher.paymentMethod == 2) {
                                this.paymentVoucher.paymentMethod = 'Transfer';
                            }
                            else if (this.paymentVoucher.paymentMethod == 3) {
                                this.paymentVoucher.paymentMethod = 'Deposit';
                            }
                            else {
                                this.paymentVoucher.paymentMethod = '';
                            }
                            if (this.paymentVoucher.paymentMode == 0) {
                                this.paymentVoucher.paymentMode = 'Cash';
                            }
                            if (this.paymentVoucher.paymentMode == 1) {
                                this.paymentVoucher.paymentMode = 'Bank';
                            }
                            if (this.paymentVoucher.paymentMode == 5) {
                                this.paymentVoucher.paymentMode = 'Advance';
                            }

                        }
                    }
                }
               
                if (this.formName == 'BankReceipt') {
                    if (this.$route.query.data == undefined) {
                        this.GetAutoCodeGenerator(this.formName);
                        this.paymentVoucher.paymentVoucherType = this.formName;
                    }
                    if (this.$route.query.data != undefined) {
                        this.attachment = true;
                        this.paymentVoucher = this.$route.query.data.message;
                        this.GetBankOpeningBalance(this.paymentVoucher.bankCashAccountId);
                        this.enableInvoiceDropdown();
                        this.paymentVoucher.paymentVoucherType = 'BankReceipt';
                        this.paymentVoucherDetails = this.$route.query.data.message.paymentVoucherDetails;
                        if (this.$i18n.locale == 'ar') {
                            if (this.paymentVoucher.paymentMethod == 1) {
                                this.paymentVoucher.paymentMethod = 'التحقق من';
                            }
                            else if (this.paymentVoucher.paymentMethod == 2) {
                                this.paymentVoucher.paymentMethod = 'تحويل';
                            }
                            else if (this.paymentVoucher.paymentMethod == 3) {
                                this.paymentVoucher.paymentMethod = 'الوديعة';
                            }
                            else {
                                this.paymentVoucher.paymentMethod = '';
                            }

                            if (this.paymentVoucher.paymentMode == 0) {
                                this.paymentVoucher.paymentMode = 'السيولة النقدية';
                            }
                            if (this.paymentVoucher.paymentMode == 1) {
                                this.paymentVoucher.paymentMode = 'مصرف';
                            }
                            if (this.paymentVoucher.paymentMode == 5) {
                                this.paymentVoucher.paymentMode = 'يتقدم';
                            }



                        }
                        if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                            if (this.paymentVoucher.paymentMethod == 1) {
                                this.paymentVoucher.paymentMethod = 'Cheque';
                            }
                            else if (this.paymentVoucher.paymentMethod == 2) {
                                this.paymentVoucher.paymentMethod = 'Transfer';
                            }
                            else if (this.paymentVoucher.paymentMethod == 3) {
                                this.paymentVoucher.paymentMethod = 'Deposit';
                            }
                            else {
                                this.paymentVoucher.paymentMethod = '';
                            }
                            if (this.paymentVoucher.paymentMode == 0) {
                                this.paymentVoucher.paymentMode = 'Cash';
                            }
                            if (this.paymentVoucher.paymentMode == 1) {
                                this.paymentVoucher.paymentMode = 'Bank';
                            }
                            if (this.paymentVoucher.paymentMode == 5) {
                                this.paymentVoucher.paymentMode = 'Advance';
                            }

                        }
                    }
                }
                if (this.formName == 'PettyCash') {
                    if (this.$route.query.data == undefined) {
                        this.GetAutoCodeGenerator(this.formName);
                        this.paymentVoucher.paymentVoucherType = this.formName;
                    }
                    if (this.$route.query.data != undefined) {
                        this.paymentVoucher = this.$route.query.data.message;
                        this.paymentVoucher.paymentVoucherType = 'PettyCash';
                        this.paymentVoucherDetails = this.$route.query.data.message.paymentVoucherDetails;
                        if (this.$i18n.locale == 'ar') {
                            if (this.paymentVoucher.pettyCash == 1) {
                                this.paymentVoucher.pettyCash = 'مؤقت';
                            }
                            if (this.paymentVoucher.pettyCash == 2) {
                                this.paymentVoucher.pettyCash = 'عام';
                            }
                            if (this.paymentVoucher.pettyCash == 3) {
                                this.paymentVoucher.pettyCash = 'تقدم';
                            }

                        }
                        if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                            if (this.paymentVoucher.pettyCash == 1) {
                                this.paymentVoucher.pettyCash = 'Temporary';
                            }
                            if (this.paymentVoucher.pettyCash == 2) {
                                this.paymentVoucher.pettyCash = 'General';
                            }
                            if (this.paymentVoucher.pettyCash == 3) {
                                this.paymentVoucher.pettyCash = 'Advance';
                            }

                        }
                        if (this.$i18n.locale == 'ar') {
                            if (this.paymentVoucher.paymentMethod == 1) {
                                this.paymentVoucher.paymentMethod = 'التحقق من';
                            }
                            else if (this.paymentVoucher.paymentMethod == 2) {
                                this.paymentVoucher.paymentMethod = 'تحويل';
                            }
                            else if (this.paymentVoucher.paymentMethod == 3) {
                                this.paymentVoucher.paymentMethod = 'الوديعة';
                            }
                            else {
                                this.paymentVoucher.paymentMethod = '';
                            }

                            if (this.paymentVoucher.paymentMode == 0) {
                                this.paymentVoucher.paymentMode = 'السيولة النقدية';
                            }
                            if (this.paymentVoucher.paymentMode == 1) {
                                this.paymentVoucher.paymentMode = 'مصرف';
                            }
                            if (this.paymentVoucher.paymentMode == 5) {
                                this.paymentVoucher.paymentMode = 'يتقدم';
                            }



                        }
                        if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                            if (this.paymentVoucher.paymentMethod == 1) {
                                this.paymentVoucher.paymentMethod = 'Cheque';
                            }
                            else if (this.paymentVoucher.paymentMethod == 2) {
                                this.paymentVoucher.paymentMethod = 'Transfer';
                            }
                            else if (this.paymentVoucher.paymentMethod == 3) {
                                this.paymentVoucher.paymentMethod = 'Deposit';
                            }
                            else {
                                this.paymentVoucher.paymentMethod = '';
                            }
                            if (this.paymentVoucher.paymentMode == 0) {
                                this.paymentVoucher.paymentMode = 'Cash';
                            }
                            if (this.paymentVoucher.paymentMode == 1) {
                                this.paymentVoucher.paymentMode = 'Bank';
                            }
                            if (this.paymentVoucher.paymentMode == 5) {
                                this.paymentVoucher.paymentMode = 'Advance';
                            }

                        }

                    }

                }
            }
        },

        created: function () {
            
            this.$emit('input', this.$route.name + this.formName);
        },

        mounted: function () {
            
            this.language = this.$i18n.locale;
            this.paymentVoucher.date = moment().format("DD MMM YYYY");
           
            if (this.formName == 'BankPay') {
                if (this.$route.query.data == undefined) {
                    this.GetAutoCodeGenerator(this.formName);
                    this.paymentVoucher.paymentVoucherType = this.formName;

                }
                if (this.$route.query.data != undefined) {
                    

                    if (this.$route.query.data.isTemporaryCashIssue) {
                        this.GetAutoCodeGenerator(this.formName);
                        this.isTemporaryCashIssue = this.$route.query.data.isTemporaryCashIssue;
                        this.paymentVoucher.temporaryCashIssueId = this.$route.query.data.id;
                        this.temporaryCashIssue = this.$route.query.data.amount - (this.$route.query.data.returnAmount + this.$route.query.data.voucherAmount);

                        //this.paymentVoucher = this.$route.query.data.message;
                        this.isShow = false
                        this.attachment = true;
                        this.paymentVoucher.bankCashAccountId = this.$route.query.data.temporaryCashAccountId;
                        this.GetBankOpeningBalance(this.paymentVoucher.bankCashAccountId);
                        this.enableInvoiceDropdown();
                        this.purchaseInvoiceRander++
                        this.paymentVoucher.paymentVoucherType = 'BankPay';
                        if (this.$i18n.locale == 'ar') {                            
                            this.paymentVoucher.paymentMode = 'السيولة النقدية';
                        }
                        if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                            this.paymentVoucher.paymentMode = 'Cash';
                        }
                    }
                    else {
                        this.paymentVoucher = this.$route.query.data.message;
                        this.isShow = false
                        this.attachment = true;
                        this.GetBankOpeningBalance(this.paymentVoucher.bankCashAccountId);
                        this.enableInvoiceDropdown();
                        this.purchaseInvoiceRander++
                        this.paymentVoucher.paymentVoucherType = 'BankPay';
                        if (this.$i18n.locale == 'ar') {
                            if (this.paymentVoucher.paymentMethod == 1) {
                                this.paymentVoucher.paymentMethod = 'التحقق من';
                            }
                            else if (this.paymentVoucher.paymentMethod == 2) {
                                this.paymentVoucher.paymentMethod = 'تحويل';
                            }
                            else if (this.paymentVoucher.paymentMethod == 3) {
                                this.paymentVoucher.paymentMethod = 'الوديعة';
                            }
                            else {
                                this.paymentVoucher.paymentMethod = '';
                            }

                            if (this.paymentVoucher.paymentMode == 0) {
                                this.paymentVoucher.paymentMode = 'السيولة النقدية';
                            }
                            if (this.paymentVoucher.paymentMode == 1) {
                                this.paymentVoucher.paymentMode = 'مصرف';
                            }
                            if (this.paymentVoucher.paymentMode == 5) {
                                this.paymentVoucher.paymentMode = 'يتقدم';
                            }
                        }
                        if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                            if (this.paymentVoucher.paymentMethod == 1) {
                                this.paymentVoucher.paymentMethod = 'Cheque';
                            }
                            else if (this.paymentVoucher.paymentMethod == 2) {
                                this.paymentVoucher.paymentMethod = 'Transfer';
                            }
                            else if (this.paymentVoucher.paymentMethod == 3) {
                                this.paymentVoucher.paymentMethod = 'Deposit';
                            }
                            else {
                                this.paymentVoucher.paymentMethod = '';
                            }
                            if (this.paymentVoucher.paymentMode == 0) {
                                this.paymentVoucher.paymentMode = 'Cash';
                            }
                            if (this.paymentVoucher.paymentMode == 1) {
                                this.paymentVoucher.paymentMode = 'Bank';
                            }
                            if (this.paymentVoucher.paymentMode == 5) {
                                this.paymentVoucher.paymentMode = 'Advance';
                            }
                        }
                    }  
                }
            }
            if (this.formName == 'PettyCash') {
                
                if (this.$route.query.data == undefined) {
                    this.GetAutoCodeGenerator(this.formName);
                    this.paymentVoucher.paymentVoucherType = this.formName;
                    if (this.$i18n.locale == 'ar') {
                        this.paymentVoucher.paymentMode = 'السيولة النقدية';

                    }
                    if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                        this.paymentVoucher.paymentMode = 'Cash';

                    }
                }
                if (this.$route.query.data != undefined) {
                    this.paymentVoucher = this.$route.query.data.message;
                    this.isShow = false
                    this.attachment = true;
                    this.saleInvoiceRander++
                    this.paymentVoucher.paymentVoucherType = 'PettyCash';
                    if (this.$i18n.locale == 'ar') {
                        if (this.paymentVoucher.pettyCash == 1) {
                            this.paymentVoucher.pettyCash = 'مؤقت';
                        }
                        if (this.paymentVoucher.pettyCash == 2) {
                            this.paymentVoucher.pettyCash = 'عام';
                        }
                        if (this.paymentVoucher.pettyCash == 3) {
                            this.paymentVoucher.pettyCash = 'تقدم';
                        }

                    }
                    if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                        if (this.paymentVoucher.pettyCash == 1) {
                            this.paymentVoucher.pettyCash = 'Temporary';
                        }
                        if (this.paymentVoucher.pettyCash == 2) {
                            this.paymentVoucher.pettyCash = 'General';
                        }
                        if (this.paymentVoucher.pettyCash == 3) {
                            this.paymentVoucher.pettyCash = 'Advance';
                        }

                    }
                    if (this.$i18n.locale == 'ar') {
                        if (this.paymentVoucher.paymentMethod == 1) {
                            this.paymentVoucher.paymentMethod = 'التحقق من';
                        }
                        else if (this.paymentVoucher.paymentMethod == 2) {
                            this.paymentVoucher.paymentMethod = 'تحويل';
                        }
                        else if (this.paymentVoucher.paymentMethod == 3) {
                            this.paymentVoucher.paymentMethod = 'الوديعة';
                        }
                        else {
                            this.paymentVoucher.paymentMethod = '';
                        }

                        if (this.paymentVoucher.paymentMode == 0) {
                            this.paymentVoucher.paymentMode = 'السيولة النقدية';
                        }
                        if (this.paymentVoucher.paymentMode == 1) {
                            this.paymentVoucher.paymentMode = 'مصرف';
                        }



                    }
                    if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                        if (this.paymentVoucher.paymentMethod == 1) {
                            this.paymentVoucher.paymentMethod = 'Cheque';
                        }
                        else if (this.paymentVoucher.paymentMethod == 2) {
                            this.paymentVoucher.paymentMethod = 'Transfer';
                        }
                        else if (this.paymentVoucher.paymentMethod == 3) {
                            this.paymentVoucher.paymentMethod = 'Deposit';
                        }
                        else {
                            this.paymentVoucher.paymentMethod = '';
                        }
                        if (this.paymentVoucher.paymentMode == 0) {
                            this.paymentVoucher.paymentMode = 'Cash';
                        }
                        if (this.paymentVoucher.paymentMode == 1) {
                            this.paymentVoucher.paymentMode = 'Bank';
                        }
                        if (this.paymentVoucher.paymentMode == 5) {
                            this.paymentVoucher.paymentMode = 'Advance';
                        }

                    }

                }
            }
            if (this.formName == 'BankReceipt') {
                if (this.$route.query.data == undefined) {
                    this.GetAutoCodeGenerator(this.formName);
                    this.paymentVoucher.paymentVoucherType = this.formName;
                }
                if (this.$route.query.data != undefined) {

                    this.paymentVoucher = this.$route.query.data.message;
                    this.isShow = false
                    this.GetBankOpeningBalance(this.paymentVoucher.bankCashAccountId);
                    this.enableInvoiceDropdown();
                    this.attachment = true;
                    this.saleInvoiceRander++;
                    this.paymentVoucher.paymentVoucherType = 'BankReceipt';
                    if (this.$i18n.locale == 'ar') {
                        if (this.paymentVoucher.paymentMethod == 1) {
                            this.paymentVoucher.paymentMethod = 'التحقق من';
                        }
                        else if (this.paymentVoucher.paymentMethod == 2) {
                            this.paymentVoucher.paymentMethod = 'تحويل';
                        }
                        else if (this.paymentVoucher.paymentMethod == 3) {
                            this.paymentVoucher.paymentMethod = 'الوديعة';
                        }
                        else {
                            this.paymentVoucher.paymentMethod = '';
                        }

                        if (this.paymentVoucher.paymentMode == 0) {
                            this.paymentVoucher.paymentMode = 'السيولة النقدية';
                        }
                        if (this.paymentVoucher.paymentMode == 1) {
                            this.paymentVoucher.paymentMode = 'مصرف';
                        }
                        if (this.paymentVoucher.paymentMode == 5) {
                            this.paymentVoucher.paymentMode = 'يتقدم';
                        }



                    }
                    if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                        if (this.paymentVoucher.paymentMethod == 1) {
                            this.paymentVoucher.paymentMethod = 'Cheque';
                        }
                        else if (this.paymentVoucher.paymentMethod == 2) {
                            this.paymentVoucher.paymentMethod = 'Transfer';
                        }
                        else if (this.paymentVoucher.paymentMethod == 3) {
                            this.paymentVoucher.paymentMethod = 'Deposit';
                        }
                        else {
                            this.paymentVoucher.paymentMethod = '';
                        }
                        if (this.paymentVoucher.paymentMode == 0) {
                            this.paymentVoucher.paymentMode = 'Cash';
                        }
                        if (this.paymentVoucher.paymentMode == 1) {
                            this.paymentVoucher.paymentMode = 'Bank';
                        }
                        if (this.paymentVoucher.paymentMode == 5) {
                            this.paymentVoucher.paymentMode = 'Advance';
                        }

                    }

                }
            }
            this.render++;
        }
    }
</script>